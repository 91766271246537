import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import './style.css'
import './Search.css';
import './Responsive.css';
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import Gleap from 'gleap'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications'

Gleap.initialize('bw5bJF3BxzTbaraehWy2mrfSbRRmJQaB')

ReactDOM.render(
  <Provider store={store}>
    <App />
    <NotificationContainer/>

  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
