import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import './NavBar/style.css';
// import './Search.scss';
import Gleap from 'gleap'

Gleap.initialize('bw5bJF3BxzTbaraehWy2mrfSbRRmJQaB')

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Page401 = React.lazy(() => import('./views/pages/page401/Page401'))
const Workspaces = React.lazy(() => import('./views/workspaces/Workspaces'))
const AccountSetup = React.lazy(() => import('./views/account-management/AccountSetup'))
const SelectPlan = React.lazy(() => import('./views/account-management/PlanSelection'))
const Checkout = React.lazy(() => import('./views/payments/CheckoutPage'))
const PaymentConfirmed = React.lazy(() => import('./views/payments/PaymentConfirmed'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgot-password/ForgotPassword'))
const ChangePassword = React.lazy(() => import('./views/pages/change-password/ChangePassword'))
const EditUser = React.lazy(() => import('./views/pages/edit-user-details/EditUser'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password Page"
              element={<ForgotPassword />}
            />
            <Route
              exact
              path="/change-password"
              name="Change Password Page"
              element={<ChangePassword />}
            />
            <Route exact path="/account/setup" name="Account Setup" element={<AccountSetup />} />
            <Route
              exact
              path="/account/select-plan"
              name="Plan Selection"
              element={<SelectPlan />}
            />
            <Route exact path="/checkout" name="Checkout" element={<Checkout />} />
            <Route
              exact
              path="/payment-confirmed"
              name="Payment Success"
              element={<PaymentConfirmed />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/401" name="Page 401" element={<Page401 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route exact path="/workspaces" name="Workspaces Page" element={<Workspaces />} />
            <Route exact path="/edit-user-details" name="Edit User Details" element={<EditUser />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
